
import React from 'react';
import ReactDOM from 'react-dom';
import Api from './api';
import './index.scss';

class GameCard extends React.Component {
    render() {
        return (
            <div key={`gcd-${this.props.game.id}`} data-id={this.props.game.id} className="game-card card" onClick={(e) => this.props.gameCardClick(this.props.game.id)}>
                <div className="game-card-img" style={{backgroundImage: `url(${Api.assetUrl + this.props.game.image})`}}></div>
                <h2>{this.props.game.title}</h2>
            </div>
        );
    }
}

class Overlay extends React.Component {

    render() {
        return (this.props.game && (
                    <div className="card">
                        <h2>{this.props.game.title} <i onClick={this.props.closeOverlay}>X</i></h2>
                        <iframe title={this.props.game.id} src={this.props.game.url} frameborder="0" width={this.props.game.width} height={this.props.game.height} ></iframe>
                        <h3>Description</h3>
                        <p>{this.props.game.description}</p>
                        <h3>Instructions</h3>
                        <p>{this.props.game.instructions}</p>

                        {
                            // tags
                            // categories
                            // bundles
                            // assets
                        }

                    </div>
                )
            )
    }
}

class Content extends React.Component {
    render() {
        return (this.props.games && (this.props.games.map((game) => (<GameCard key={`gc-${game.id}`} game={game} gameCardClick={this.props.gameCardClick} />))))
            || (<span>Loading ...</span>)
    }
}

class CategoryLi extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
        };
    }

    render() {
        return (
            <li key={this.props.category.id} className={`category${this.state.toggle ? " selected" : ""}`} data-id={this.props.category.id} onClick={(e) => {
                e.stopPropagation();
                this.setState({toggle: !this.state.toggle}, () => this.props.categoryClick(this.props.category.id, this.state.toggle));
            }}>
                {this.props.category.name}
                <ul className={`category-children cc-depth-${this.props.depth}`}>
                    {this.props.category.children.map((c) => (<CategoryLi key={c.id} category={c} depth={this.props.depth+1} categoryClick={this.props.categoryClick} />))}
                </ul>
            </li>
        )
    }
}

class Nav extends React.Component {

    render() {
        return (this.props.categories && (<ul>
                {this.props.categories.map((category) => (<CategoryLi key={category.id} category={category} depth={0} categoryClick={this.props.categoryClick} />))}
            </ul>)
            ) || (<span>Loading ...</span>)
    }
}

class Search extends React.Component {
    render() {
        let timeoutId;
        return (
                <input type="text" name="search" placeholder="Search for Games ..." onChange={(e) => {
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(() => this.props.searchQueryChanged(e.target.value), 500);
                }} />
        )
    }
}

class Body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentGame: null,
            selectedCategories: [],
            searchQuery: '',
            page: 1,
            loading: true,
            navMobileShow: false,
        };

        this.categoryClick = this.categoryClick.bind(this);
        this.searchQueryChanged = this.searchQueryChanged.bind(this);
        this.gameCardClick = this.gameCardClick.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
        this.infiniteScroll = this.infiniteScroll.bind(this);
        this.navToggle = this.navToggle.bind(this);
    }

    componentDidMount() {
        this.search();
        this.getCategories();
    }

    getGame(id) {
        Api.get(id).then((game) => this.setState({currentGame: game}));
    }

    search() {
        this.setState({loading: true}, () => {
            Api.search(this.state.searchQuery, this.state.page, this.state.selectedCategories)
            .then((response) => this.setState({
                games: (this.state.page === 1) ? response.games : [...this.state.games, ...response.games],
                latestCount: response.count,
                loading: false,
            }))
        });
    }

    getCategories() {
        Api.getCategories().then((categories) => this.setState({categories: categories}));
    }

    infiniteScroll(e) {

        if(this.state.loading) {
            return;
        }

        let div = e.target,
            percY = (div.offsetHeight + div.scrollTop) / div.scrollHeight;

        if(percY > 0.8) {
            this.setState({page: this.state.page + 1}, this.search);
        }
    }

    categoryClick(id, toggle) {
        let index = this.state.selectedCategories.indexOf(id);

        (index === -1 && this.state.selectedCategories.push(id))
        || (delete this.state.selectedCategories[index]);

        this.setState({page: 1}, this.search);
    }

    gameCardClick(id) {
        this.getGame(id);
    }

    closeOverlay(id) {
        this.setState({currentGame: null});
    }

    searchQueryChanged(searchQuery) {
        this.setState({searchQuery: searchQuery, page: 1}, this.search);
    }

    navToggle() {
        this.setState({navMobileShow: !this.state.navMobileShow})
    }

    render() {
        return [
            <div className="background" key="searchRoot" id="search"><a href="javascript:;" id="nav-toggle" onClick={this.navToggle}>M</a><Search searchQueryChanged={this.searchQueryChanged} /></div>,
            <div className={`background` + (this.state.navMobileShow ? ' nav-mobile-show' : '')} key="navRoot" id="nav"><Nav categories={this.state.categories} categoryClick={this.categoryClick} /></div>,
            <div className="background" key="contentRoot" id="content" onScroll={this.infiniteScroll}><Content games={this.state.games} gameCardClick={this.gameCardClick} /></div>,
            <div className={`overlay` + (this.state.currentGame ? ' open' : '')} key="overlayRoot" id="overlay"><Overlay game={this.state.currentGame} closeOverlay={this.closeOverlay} /></div>,
        ]
    }
}

ReactDOM.render(<Body />, document.getElementById('page'));
