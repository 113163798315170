
const https = require('https')

const apiHost = 'gamesapi.dirlik.nl';
const assetUrl = '//' + apiHost + '/assets/';

export default {

    apiHost: apiHost,
    assetUrl: assetUrl,

    options: {
        hostname: apiHost,
        path: '/',
        method: 'POST',
        headers: {
            'X-API-KEY': '127cf7b8aca6f1f0e34b615072ac119e378e1c66c555ec37aadb10bd2df9c65b',
            'Content-Type': 'application/json',
        },
    },

    call(data) {

        return new Promise((resolve, reject) => {

            const req = https.request(this.options, res => {
                res.setEncoding('utf8')
                res.on('data', d => {
                    resolve(JSON.parse(d))
                })
            })

            req.on('error', error => {
                // reject(JSON.parse(error))
                reject(error)
                console.log(error)
            })

            req.write(JSON.stringify(data))
            req.end()
        });


    },

    async get(id) {
        return await this.call({id: id});
    },

    async search(query, page, categories) {
        return await this.call({search: query, page: page, categories: categories});
    },

    async getCategories() {
        return await this.call({getCategoryTree: 1});
    },

}
